import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/index"
import Seo from "../components/seo"

//Page content
import JobOfferList from "../components/team/job-offer"
import TeamList from "../components/team/"

const TeamPage = (props) => {

    const data = useStaticQuery(graphql`
  query TeamPageQuery {
    contentfulPage(slug: {eq: "/equipe/"}) {
      title
      description {
        description
      }
      keywords
      pageContent {
        ... on ContentfulTeam {
            __typename
            id
            title
            teamList {
                id
                name
                role
                quote
                image {
                    fluid(maxWidth: 300, quality:100) {
                      ...GatsbyContentfulFluid_noBase64
                    }
                    description
                    title
                }
            }

        }
        ... on ContentfulJobOfferList {
            __typename
            id
            title
            jobOfferList {
                title
                id
                applyLink
                description {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
      }
    }
  }
  `)

    const team = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulTeam");
    const jobOfferList = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulJobOfferList");

    return (
        <Layout>
            <Seo
                title={data.contentfulPage.title}
                description={data.contentfulPage.description.description}
                keywords={data.contentfulPage.keywords} />
            <TeamList title={team.title} teamList={team.teamList} />
            <JobOfferList jobOfferList={jobOfferList} />
        </Layout>
    )
}

export default TeamPage