import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const TeamList = ({ title, teamList }) => {

    return (
        <section className="common-wrapper overflow-hidden px-4 py-20 py-10-md">
            <header className="flex justify-between items-center pb-20 pb-10-md px-0-md">
                <h4 className="f1 text-blue-dark font-bold m-0">{title}</h4>
            </header>
            <ul className="flex justify-evenly flex-wrap">
                {teamList.map(member => {
                    return (
                        <li key={member.id} className="flex-col w-20-per flex-grow-1 w-40-per-md max-width-250-px max-width-100-per-xs w-full-xs my-5 bg-white py-4 px-4 mx-4 br-2 text-blue-dark shadow-1">
                            <header className="flex flex-col items-center justify-center py-2">
                                <p className="font-bold f4 text-blue-dark mt-0 mb-2">{member.name}</p>
                                <p className="font-normal f6 mt-1 mb-2">{member.role}</p>
                                <div className="w-full overflow-hidden h-auto-md flex justify-center items-center p-5">
                                    { member.image && member.image.fluid &&
                                        <Img
                                            className="w-full"
                                            fluid={member.image.fluid}
                                            alt={member.image ? member.image.description : ''}
                                        />
                                    }
                                </div>

                            </header>
                            <p className="block font-normal f5 text-align-center">{member.quote}</p>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default TeamList