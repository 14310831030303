import React, { useState } from "react"

const JobOffer = ({ jobOfferList }) => {

    let [jobOffers, setjobOffers] = useState(jobOfferList.jobOfferList.map(jobOffer => { let o = { ...jobOffer, isActive: false }; return o }));

    const togglejobOffer = (activeJobOffer) => {

        let newjobOffersArray = jobOffers.map(jobOffer => {
            if (jobOffer.id === activeJobOffer) {
                jobOffer.isActive = !jobOffer.isActive;
            }

            return jobOffer
        })

        setjobOffers(newjobOffersArray);
    }

    return (
        <section className="common-wrapper mt-10 px-15 px-4-md">
            <div className="flex justify-between text-align-left py-15 py-10-md py-5-xs">
                <div>
                    <h2 className="f2 text-blue-dark font-bold">{jobOfferList.title}</h2>
                    <ol className="common-list m-0 px-0">
                        {jobOffers.map((jobOffer) => {
                            return (
                                <li key={jobOffer.id} className="common-list-item flex my-10 justify-between relative w-full">
                                    <div className={jobOffer.isActive ? "w-full bg-grey-4 pt-9 px-8 relative use-case-dropdown active" : "w-full bg-grey-4 pt-9 px-8 relative use-case-dropdown"}>
                                        <h3 className="f3 text-blue-dark mt-0 mt-8-md">{jobOffer.title}</h3>
                                        <div className="block f4 font-normal lh-4 text-blue-dark" dangerouslySetInnerHTML={{ __html: jobOffer.description.childMarkdownRemark.html }}></div>
                                        <span onClick={() => togglejobOffer(jobOffer.id)} className="cursor-pointer absolute t-9 r-10 flex font-bold justify-center items-center underline">
                                            {jobOffer.isActive ? 'Lire moins' : 'Lire plus'}
                                        </span>
                                        {jobOffer.applyLink ? <span className="mt-8 block"><a className="f3 text-blue-dark underline" target="_blank" rel="noopener noreferrer" href={jobOffer.applyLink}>Postuler ici</a></span> : ''}
                                    </div>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            </div>
        </section>
    )
}

export default JobOffer